import mps from './mps.json';
import './App.css';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import { useState } from 'react';

const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
        keys: ['name', 'district'],
        threshold: 0.3,
        includeScore: true
    });

    return (value) => {
        if (!value.length) {
            return [];
        }

        return fuse.search(value).map(result => result.item);
    };
}

const App = () => {
  const [selected, setSelected] = useState(null);
  const [barWidth, setBarWidth] = useState(0);
  const getPercentage = (score, maxScore) => (1 - (score / maxScore)).toFixed(2) * 100;
  const getTwatScore = (percentage) => {
    switch (true) {
      case percentage < 20:
        return 'NOT A TWAT';
      case percentage < 50:
        return 'SLIGHT WHIFF OF TWAT';
      case percentage < 65:
        return 'PROBABLY NOT A COMPLETE TWAT';
      case percentage < 80:
        return 'DEFINITELY A TWAT';
      default:
        return 'MASSIVE TWAT';

    }
  }
  const onChange = (e) => {
    const mp = mps.find(mp => mp.name === e);
    setSelected(mp);
    window.gtag('event', 'click', {
      'event_category': 'mp',
      'event_label': mp.name
    });
    setTimeout(function () {
      setBarWidth(`${getPercentage(mp.score, mp.maxScore)}%`);
    }, 150);
  }
  const searchAgain = (e) => {
    e.preventDefault();
    setSelected(null);
    setBarWidth(0);
    window.gtag('event', 'click', {
      'event_category': 'link',
      'event_label': 'search again'
    });
  }
  const renderOption = (props, data, snapshot, className) => {
    return <button {...props} className={className} type="button">
      {data.name}<span style={{ color: "#777774" }}> - {data.district} </span>
    </button>
  }

  return (
    <div>
      <div className="container">
        <h1>How much of a TWAT is your MP?</h1>
        {selected ? (
          <div className="mp-card">
            <h2>{selected.name}</h2>
            <div className="mp-card__detail">{selected.party} - {selected.district}</div>
            <div className="mp-card__bar">
              <div className="mp-card__bar__progress" style={{width: barWidth}} />
            </div>
            <div className="mp-card__score" key="score">
              <div className="mp-card__score__percentage">{getPercentage(selected.score, selected.maxScore)}%</div>
              {getTwatScore(getPercentage(selected.score, selected.maxScore))}
            </div>
            <p className="mp-card__vote-link"><a href={selected.url} target="_blank" rel="noreferrer">See how they've voted</a></p>
            <p className="mp-card__link"><a href="/" onClick={searchAgain}>Search again</a></p>
          </div>
        ) : (
          <div className={`search`}>
            <SelectSearch
              autoFocus={true}
              options={mps}
                search
                onChange={onChange}
                placeholder="Search by name or council"
                filterOptions={(options) => {
                  const filter = fuzzySearch(options);
                  return (q) => filter(q).slice(0, 8);
              }}
              printOptions="on-focus"
              renderOption={renderOption}
            />
          </div>
        )}
        <footer>
          <p>Data collected from <a href="https://www.theyworkforyou.com/" target="_blank" rel="noreferrer">theyworkforyou.com</a>.</p>
          <p>All TWAT scores are produced by a sophisticated AI using quantum machine learning.</p>
          <p>Not to be taken seriously.</p>
          <p>Made by <a href="https://toddfrancis.co.uk" target="_blank" rel="noreferrer">Todd</a>.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
